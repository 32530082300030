"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductListScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var moment = require("moment");
var React = require("react");
var list_search_component_1 = require("../../common/components/list-search/list-search.component");
var sales_channel_filter_component_1 = require("../../common/components/list-search/sales-channel-filter.component");
var pagination_list_control_1 = require("../../common/components/repository-list/controls/pagination-list-control");
var pagination_control_1 = require("../../common/components/repository-list/controls/pagination.control");
var list_empty_1 = require("../../common/components/repository-list/empty/list-empty");
var list_loader_1 = require("../../common/components/repository-list/loader/list-loader");
var repository_list_1 = require("../../common/components/repository-list/repository-list");
var permissions_1 = require("../../common/libs/permissions/permissions");
var clickable_row_1 = require("../../components/clickable-row");
var badge_1 = require("../../components/libs/badge/badge");
var permission_wrapper_1 = require("../../components/permission-wrapper");
var has_supplier_price_filter_component_1 = require("./components/has-supplier-price-filter.component");
var product_distributed_filter_component_1 = require("./components/product-distributed-filter.component");
var product_status_filter_component_1 = require("./components/product-status-filter.component");
var ProductListScreen = (function (_super) {
    __extends(ProductListScreen, _super);
    function ProductListScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                limit: 20,
                page: 0,
                query: '',
                sort: '',
                distributed: true,
            },
        });
        _this.state = {
            total: 0
        };
        return _this;
    }
    ProductListScreen.prototype.render = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-15" },
                    React.createElement("div", { className: 'display-flex align-items-center mb-8' },
                        React.createElement("h2", { className: 'mr-4' }, "Term\u00E9kek"),
                        React.createElement("span", null,
                            this.state.total,
                            " db"),
                        React.createElement("div", { className: 'ml-6' },
                            React.createElement(permission_wrapper_1.PermissionWrapper, { permissions: permissions_1.PERMISSIONS.PRODUCTS.create },
                                React.createElement(button_1.Button, { onClick: function () { return router_provider_1.RouterProvider.goTo('/products/create'); }, title: (0, trans_1.trans)('product.list.button.create.title'), iconLeft: 'fal fa-plus', className: 'button-primary-link button-size-normal' }))))),
                React.createElement("div", { className: "col-9" },
                    React.createElement(list_search_component_1.ListSearchComponent, { "control$": this.control }))),
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-8" },
                    React.createElement(sales_channel_filter_component_1.SalesChannelFilterComponent, { "control$": this.control })),
                React.createElement("div", { className: "col-8" },
                    React.createElement(product_status_filter_component_1.ProductStatusFilterComponent, { "control$": this.control })),
                React.createElement("div", { className: "col-8" },
                    React.createElement(product_distributed_filter_component_1.ProductDistributedFilterComponent, { "control$": this.control })),
                React.createElement("div", { className: "col-8" },
                    React.createElement(has_supplier_price_filter_component_1.HasSupplierPriceFilterComponent, { "control$": this.control }))),
            React.createElement(repository_list_1.RepositoryList, { control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, onChange: function (ctx) { return _this.handleListChange(ctx); }, repository: repository_1.Repository.use('default'), endpoint: '/admin/product', renderItemsWrapper: function (ctx) { return _this.renderTable(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); }, renderEmpty: function () { return (React.createElement(list_empty_1.ListEmpty, null)); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, null)); } }),
            React.createElement(pagination_control_1.PaginationControl, { "control$": this.control, limitOptions: [
                    { name: 20, value: 20 },
                    { name: 50, value: 50 },
                    { name: 100, value: 100 },
                    { name: 500, value: 500 },
                ] }));
    };
    ProductListScreen.prototype.renderTable = function (ctx) {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return ctx.renderItems(ctx); } });
    };
    ProductListScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('sku'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('vintage'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 18 }, this.setHeaderCell('manufacturer'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 18 }, this.setHeaderCell('title'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 17 }, this.setHeaderCell('subTitle'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('supplierPrice'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('b2cCategory'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('salesChannels'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 20 }, this.setHeaderCell('status'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('createdAt'))));
    };
    ProductListScreen.prototype.setHeaderCell = function (p, enableSort) {
        var _this = this;
        if (enableSort === void 0) { enableSort = true; }
        return {
            sortValue: this.getSortValue(p),
            onSort: function (property, value) { return _this.handleSort(property, value); },
            enableSort: enableSort,
            title: (0, trans_1.trans)("product.table.header.".concat(p)),
            property: p
        };
    };
    ProductListScreen.prototype.renderItem = function (ctx, item, index) {
        var _a, _b, _c, _d, _e;
        var drs = (item === null || item === void 0 ? void 0 : item.drs) ? '(DRS)' : '';
        var title = "".concat((_a = item === null || item === void 0 ? void 0 : item.title) === null || _a === void 0 ? void 0 : _a.hu, " ").concat(drs) || '-';
        var hasSupplierPrice = item.hasSupplierPrice ? 'check' : 'close';
        return React.createElement(clickable_row_1.ClickableRow, { key: item._id, className: 'cursor-pointer', href: "/products/".concat(item === null || item === void 0 ? void 0 : item._id) },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement("span", null, item === null || item === void 0 ? void 0 : item.sku)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("span", null, item === null || item === void 0 ? void 0 : item.vintage)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 18 },
                React.createElement("span", null, (_c = (_b = item === null || item === void 0 ? void 0 : item.manufacturer) === null || _b === void 0 ? void 0 : _b.name) === null || _c === void 0 ? void 0 : _c.hu)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 18 },
                React.createElement("span", null, title)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 17 },
                React.createElement("span", null, ((_d = item === null || item === void 0 ? void 0 : item.subTitle) === null || _d === void 0 ? void 0 : _d.hu) || '-')),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement("i", { className: "fas fa-".concat(hasSupplierPrice) })),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("span", null, (_e = item === null || item === void 0 ? void 0 : item.b2cCategoryName) === null || _e === void 0 ? void 0 : _e.hu)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15, className: 'flex-wrap' }, ((item === null || item === void 0 ? void 0 : item.salesChannels) || []).map(function (c) { return (React.createElement(badge_1.Badge, { type: 'basic', className: 'whitespace-nowrap mr-2 mb-2', title: c, key: c })); })),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 20 },
                React.createElement("span", null, this.renderStatus(item))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("span", null, moment(item === null || item === void 0 ? void 0 : item.createdAt).format('YYYY.MM.DD.'))));
    };
    ProductListScreen.prototype.renderStatus = function (item) {
        var status = item === null || item === void 0 ? void 0 : item.status;
        if (status !== 'MarketingApproved') {
            return (0, trans_1.trans)("product.status.".concat(status));
        }
        if (item.published === null) {
            return (0, trans_1.trans)('product.status.waitingToPublish');
        }
        return item.published ? (0, trans_1.trans)('product.status.published') : (0, trans_1.trans)('product.status.unpublished');
    };
    ProductListScreen.prototype.handleListChange = function (ctx) {
        var _a, _b;
        var total = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.total;
        this.setState({ total: (_b = ctx.state) === null || _b === void 0 ? void 0 : _b.total });
        if (this.props.totalChange) {
            this.props.totalChange(total);
        }
    };
    ProductListScreen.prototype.handleSort = function (property, value) {
        this.control.sort(property, value);
    };
    ProductListScreen.prototype.getSortValue = function (property) {
        var _a;
        return ((_a = this.control.data.sort) === null || _a === void 0 ? void 0 : _a[property]) || 0;
    };
    return ProductListScreen;
}(abstract_component_1.AbstractComponent));
exports.ProductListScreen = ProductListScreen;
